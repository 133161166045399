<template>
    <div>
        <!-- TODO: Header Image Optimization -->
        <div v-if="dataLoaded" id="article-header" class="entry-short" v-bind:style="{ backgroundImage: 'url(' + headerImage + ')' }">
            <div class="context shadow">
                <h1>{{ localize(newsArticle.attributes.title) }}</h1>
                <h3>{{ dateFormat(articleDate) }}</h3>
            </div>
        </div>
        <div v-if="dataLoaded" class="article">
            <p class="large text-center">
                {{ localize(newsArticle.attributes.excerpt) }}
            </p>
            <p class="article-content" v-html="md(localize(newsArticle.attributes.content))"></p>
            <ImageGallery v-if="newsArticle.attributes.imageGallery" :cmsBlock="newsArticle.attributes.imageGallery"/>
        </div>
    </div>
</template>

<script>
    import { newsArticleQuery } from '@/queries'
    import { config } from '@/config'
    import { dateFormat } from '@/helper'

    export default {
        name: 'Article',

        components: {
            ImageGallery: () => import('@/components/PageBuilder/ImageGallery')
        },

        data() {
            return {
                newsArticle: null,
                dateFormat: dateFormat
            }
        },

        apollo: {
            newsArticle: {
                query: newsArticleQuery,
                variables() {
                    return {
                        title: this.$route.params.title
                    }
                },
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                update: data => data.newsArticles.data[0]
            }
        },

        metaInfo() {
            var meta = []

            if(this.metaTitle){
                meta.push({
                    vmid: 'og:title',
                    name: 'og:title',
                    content: this.metaTitle
                })
            }
            if(this.metaDescription){
                meta.push({
                    vmid: 'description',
                    name: 'description',
                    content: this.metaDescription
                })
                meta.push({
                    vmid: 'og:description',
                    name: 'og:description',
                    content: this.metaDescription
                })
            }
            if(this.metaImage){
                meta.push({
                    vmid: 'og:image',
                    name: 'og:image',
                    content: this.metaImage
                })
            }

            return {
                title: this.metaTitle,
                meta: meta
            }
        },

        computed: {
            dataLoaded() {
                return this.newsArticle != null
            },

            articleDate() {
                return this.newsArticle.attributes.dateOverride ? new Date(this.newsArticle.attributes.dateOverride) : new Date(this.newsArticle.attributes.createdAt)
            },

            headerImage() {
                return this.newsArticle.attributes.headerImage ?
                    this.getImageUrl(this.localize(this.newsArticle.attributes.headerImage).data.attributes.url) :
                    require('@/assets/Teamwork.png')
            },

            metaTitle() {
                if(this.newsArticle){
                    var title = this.newsArticle.attributes.metaTitle ? this.localize(this.newsArticle.attributes.metaTitle) : this.localize(this.newsArticle.attributes.title)
                    return config.globalPageTitle + ' - ' + title
                } else {
                    return null
                }
            },

            metaDescription() {
                if(this.newsArticle){
                    return this.newsArticle.attributes.metaDescription ?
                        this.localize(this.newsArticle.attributes.metaDescription) : this.localize(this.newsArticle.attributes.excerpt)
                } else {
                    return null
                }
            },

            metaImage() {
                if(this.newsArticle) {
                    return this.newsArticle.attributes.metaImage ? this.newsArticle.attributes.metaImage.data.attributes.url : this.localize(this.newsArticle.attributes.titleImage).data.attributes.url
                } else {
                    return null
                }
            }
        }
    }
</script>

<style lang="scss">
    #article-header {
        background-size: cover;
        height: 530px;

        .context {
            padding-left: 30px;
            padding-right: 30px;
        }

        h1 {
            font-size: 76px;

            @include breakpoint('max-width') {
                font-size: 48px;
            }
            @include breakpoint('tablet') {
                font-size: 32px;
            }
        }
    }

    .article {
        max-width: 1024px;
        margin: 0 auto;
        padding: 0 20px;
        padding-bottom: 70px;

        p {
            font-size: 18px;
            text-align: start;

            &.large {
                font-size: 30px;
                text-align: center;
            }
        }

        h4 {
            font-size: 18px;
        }
    }
</style>
